/* eslint react/jsx-key: off */
import React from "react";
import {
  BulkDeleteWithConfirmButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  TextField,
  ShowButton,
  ReferenceField,
  BooleanField,
  Pagination,
  useTranslate,
  FunctionField,
  Button,
  SimpleShowLayout,
  DateField,
  TopToolbar,
  ExportButton,
} from "react-admin";
import { Link } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import { Chip } from "@mui/material";

import IdAndNameField from "../IdAndNameField";
import FromNowDateField from "../FromNowDateField";
import JsonField from "../AlembicJsonFields/JsonField";

import Aside from "./Aside";

const QuickFilter = ({ label }) => {
  const translate = useTranslate();
  return <Chip sx={{ marginBottom: 1 }} label={translate(label)} />;
};

const LinktokenPanel = () => (
  <SimpleShowLayout>
    <ReferenceField label="ID" source="id" reference="Linktoken" link="show">
      <IdAndNameField nameField="remote_name" />
    </ReferenceField>
    <TextField source="type" />
    <TextField source="remote_name" />
    <TextField source="remote_id" label="Remote ID" />
    <ReferenceField
      label="Parent ID"
      source="parent_id"
      reference="Linktoken"
      link="show"
    >
      <IdAndNameField nameField="remote_name" />
    </ReferenceField>

    <TextField source="remote_token" label="Vault Remote Token UUID" />
    <TextField
      source="remote_token_alt"
      label="Vault Remote Token (alt) UUID"
    />
    <FromNowDateField label="Created At" source="created_at" showTime />
    <FromNowDateField label="Updated At" source="updated_at" showTime />
    <JsonField source="error" label="last error" fullWidth />
  </SimpleShowLayout>
);

const LinktokenFilter = ({ permissions, ...props }) => (
  <div>
    <Filter {...props}>
      <SearchInput source="q" alwaysOn />
      <ReferenceArrayInput source="organization_id" reference="Organization">
        <AutocompleteArrayInput
          source="ids"
          optionText="name"
          optionValue="id"
        />
      </ReferenceArrayInput>

      <SearchInput source="container_id" placeholder="Container ID" />

      <SearchInput source="user_id" placeholder="User ID" />

      <QuickFilter
        source="activeOnly"
        label="linktoken.chip.activeOnly"
        defaultValue={true}
      />

      <QuickFilter
        source="failedTokensOnly"
        label="linktoken.chip.failedOnly"
        defaultValue={true}
      />

      <QuickFilter
        source="activeOrgOnly"
        label="linktoken.chip.activeOrgOnly"
        defaultValue={true}
      />
    </Filter>
  </div>
);

const LinktokenBulkActionButtons = (props) => (
  <BulkDeleteWithConfirmButton {...props} />
);

const LinktokenPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const ListActions = ({ permissions }) => (
  <TopToolbar>
    <LinktokenFilter context="button" permissions={permissions} />
    <ExportButton maxResults={20000} label="Export" />
  </TopToolbar>
);

const LinktokenList = ({ permissions, ...props }) => (
  <List
    {...props}
    actions={<ListActions permissions={permissions} />}
    filters={<LinktokenFilter permissions={permissions} />}
    sort={{ field: "remote_name", order: "ASC" }}
    aside={<Aside />}
    bulkActionButtons={<LinktokenBulkActionButtons />}
    pagination={<LinktokenPagination />}
  >
    <Datagrid optimized expand={<LinktokenPanel />}>
      <TextField source="id" />

      <TextField source="type" />

      <TextField source="remote_name" />

      <DateField label="Created At" source="created_at" showTime />
      <DateField label="Updated At" source="updated_at" showTime />

      <ReferenceField
        label="Organization"
        source="organization_id"
        reference="Organization"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        label="Container"
        source="container_id"
        reference="Container"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>

      <BooleanField source="valid_token" />
      <BooleanField source="enabled" />
      <BooleanField source="deleted" />

      <FunctionField
        label="Edit"
        render={(record) => {
          return (
            <>
              <Button
                component={Link}
                to={{
                  pathname: `/Linktoken/${record.id}`,
                  state: {
                    record: {
                      linktoken_id: record && parseInt(record.id, 10),
                    },
                  },
                }}
                icon={EditIcon}
                label="Edit"
              >
                <EditIcon />
              </Button>
            </>
          );
        }}
      />

      <ShowButton label="Show" />
    </Datagrid>
  </List>
);

export default LinktokenList;
