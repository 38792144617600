import { gql } from "@apollo/client";

export const CAUSAL_CHAINS_SUMMARY_ALL = gql`
  query CausalChainsSummaryAll(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: CausalChainsSummaryFilter
  ) {
    items: CausalChainsSummaryAll(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      container_id
      id
      periodical
      status
      created_at
      summary
    }
    total: _CausalChainsSummaryAllMeta(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      count
      __typename
    }
  }
`;

export const CAUSAL_CHAINS_SUMMARY_ONE = gql`
  query CausalChainsSummaryOne($id: ID!) {
    CausalChainsSummaryOne(id: $id) {
      id
      container_id
      created_at
      periodical
      status
      summary
    }
  }
`;

export const UPDATE_ADMIN_CAUSAL_CHAINS_SUMMARY = gql`
  mutation UpdateAdminCausalChainsSummary(
    $data: CausalChainsSummaryUpdateInput!
  ) {
    updateAdminCausalChainsSummary(data: $data) {
      id
      container_id
      status
    }
  }
`;
